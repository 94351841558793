<template>
  <app-layout-main class="p-4">
    <div
      class="
        container
        px-1
        py-8
        m-auto
        mx-auto
        md:px-8
        max-w-xl max-h-90
        space-y-24
        bg-contain bg-app-logo bg-no-repeat bg-center
      "
    >
      <app-logo-cryptopay class="-mt-20 h-10 w-full" />

      <app-card class="-top-8 m-10">
        <h3 v-if="verified" class="mb-4 font-semibold md:text-lg text-center">
          {{ $t("auth.verify.verified_email") }}
        </h3>

        <app-alert-error :message="message" />

        <app-button
          :loading="loading"
          @onClick="returnToHome"
          eventName="return-to-home"
        >
          {{ $t("auth.verify.return_to_home") }}
        </app-button>
      </app-card>
    </div>
    <app-alert />
  </app-layout-main>
</template>

<script>
export default {
  data() {
    return {
      verified: false
    }
  },
  computed: {
    loading() {
      return this.$store.getters["authStore/loading"];
    },

    message() {
      return this.$store.getters["authStore/message"];
    },
  },
  async mounted() {
    let email = this.$route.query.email;
    if (!email) {
      this.$router.push({ name: "/" });
      return;
    }

    const refreshUser = await this.$store.dispatch("authStore/refreshUser");

    if (refreshUser == null) {
      return;
    }

    this.verified = true;
  },
  methods: {
    returnToHome() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>